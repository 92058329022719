<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <!-- menu list to pick parent menu -->
          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">
              Выберите раздел: <br />
              <small>(Не обязательно) </small>
            </a-col>

            <a-col :span="12">
              <a-select
                v-model="form.parent"
                :filter-option="filterOption"
                option-filter-prop="children"
                placeholder="Выберите департамент"
                show-search
                style="width: 100%"
              >
                <a-select-option
                  v-for="act in aboutList.data"
                  :key="act.id"
                  :value="act.id"
                >
                  (ID: {{ act.id }}) {{ act.title || "-" }}
                </a-select-option>
              </a-select>
            </a-col>
          </a-row>

          <!-- position -->
          <a-row align="middle" class="flex-baseline input-form__group">
            <a-col :span="4" class="form_title">
              {{ $t("MenuPosition") }}:
            </a-col>
            <a-col :span="12">
              <a-input-number
                v-model="form.position"
                placeholder="Введите позицию"
              />
              <div v-if="!form.position" class="error-text">
                Введите позицию
              </div>
            </a-col>
          </a-row>

          <!-- active toggle -->
          <a-row style="margin-bottom: 25px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <!-- editor -->
          <a-row style="margin-top: 25px" type="flex">
            <a-col :span="4" class="form_title sm-mb">
              {{ $t("Content") }}:
            </a-col>

            <a-col v-show="langTab === 'oz'" :span="20">
              <!-- :key="langtab" -->
              <editor
                :value="form.content.oz"
                :initial-value="form.content.oz"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="form.content.oz = editorChangeHandler(arguments[0])"
              />
            </a-col>

            <a-col v-show="langTab === 'uz'" :span="20">
              <!-- :key="langtab" -->
              <editor
                :value="form.content.uz"
                :initial-value="form.content.uz"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="form.content.uz = editorChangeHandler(arguments[0])"
              />
            </a-col>

            <a-col v-show="langTab === 'qr'" :span="20">
              <!-- :key="langtab" -->
              <editor
                :value="form.content.qr"
                :initial-value="form.content.qr"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="form.content.qr = editorChangeHandler(arguments[0])"
              />
            </a-col>

            <a-col v-show="langTab === 'ru'" :span="20">
              <!-- :key="langtab" -->
              <editor
                :value="form.content.ru"
                :initial-value="form.content.ru"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="form.content.ru = editorChangeHandler(arguments[0])"
              />
            </a-col>

            <a-col v-show="langTab === 'en'" :span="20">
              <!-- :key="langtab" -->
              <editor
                :value="form.content.en"
                :initial-value="form.content.en"
                :init="{
                  ...tinymce.default.props,
                  setup: (editor) => {
                    editor.ui.registry.addButton('spoiler-btn', {
                      icon: 'comment-add',
                      tooltip: 'Спойлер',
                      onAction: () => {
                        spoiler.modalVisibility = editor
                        spoilerHandler()
                      }
                    })
                    editor.ui.registry.addButton('storage-btn', {
                      icon: 'non-breaking',
                      tooltip: 'Хранилище изображений',
                      onAction: () => {
                        openStoreModal()
                        editorInstance = editor
                      }
                    })
                  }
                }"
                @input="form.content.en = editorChangeHandler(arguments[0])"
              />
            </a-col>
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="4" />

            <a-col :span="18">
              <a-row>
                <a-button
                  style="margin-right: 10px"
                  @click="
                    $router.push({
                      name: 'aboutAct',
                      params: { ...$route.params }
                    })
                  "
                >
                  <a-icon type="close" />Отмена
                </a-button>

                <a-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="
                    submit(form.id ? (saveback = false) : (saveback = true))
                  "
                >
                  <a-icon type="check" />
                  {{ form.id ? $t("Save") : $t("Add") }}
                </a-button>

                <a-button
                  v-if="form.id"
                  style="margin-left: 10px"
                  type="primary"
                  @click="submit((saveback = true))"
                >
                  <a-icon type="check" />
                  {{ $t("SaveAndExit") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import spoiler from "@/mixins/spoiler.js"
export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue")
  },
  mixins: [spoiler],
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        content: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
          qr: ""
        },
        activity: null,
        is_active: true,
        position: 1,
        parent: null
      },
      aboutList: [],
      langTab: "oz"
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    this.aboutList = await this.$api.get(
      "/admin/budget/activity-information/list",
      {
        params: {
          activity: this.$route.params.act_id
        }
      }
    )
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      if (!this.$route.params.id) return
      let id = this.$route.params.id
      const { data } = await this.$api.get(
        `/admin/budget/activity-information/${id}/detail/`
      )
      this.form = {
        ...data,
        content: {
          ru: data.content_ru,
          en: data.content_en,
          oz: data.content_oz,
          uz: data.content_uz,
          qr: data.content_qr
        }
      }
    },
    async submit(saveback) {
      let f = new FormData()
      this.form = Object.assign(this.form, {
        activity: this.form.activity
          ? this.form.activity
          : this.$route.params.act_id,
        content_ru: this.form.content.ru,
        content_uz: this.form.content.uz,
        content_oz: this.form.content.oz,
        content_en: this.form.content.en,
        content_qr: this.form.content.qr
      })
      delete this.form.content
      if (this.form.parent === null || this.form.parent === "") {
        delete this.form.parent
      }
      Object.keys(this.form).forEach((key) => {
        if (
          (key === "content_ru" &&
            (this.form[key] === "null" || !this.form[key])) ||
          (key === "content_uz" &&
            (this.form[key] === "null" || !this.form[key])) ||
          (key === "content_oz" &&
            (this.form[key] === "null" || !this.form[key])) ||
          (key === "content_en" &&
            (this.form[key] === "null" || !this.form[key])) ||
          (key === "content_qr" &&
            (this.form[key] === "null" || !this.form[key]))
        ) {
          f.append(key, " ")
          delete this.form[key]
        } else {
          f.append(key, this.form[key])
        }
      })
      const createOrUpdate = {
        url:
          this.form && this.form.id
            ? "/admin/budget/activity-information/" + this.form.id + "/update/"
            : "/admin/budget/activity-information/create/",
        method: this.form && this.form.id ? "patch" : "post"
      }
      try {
        let res = await this.$api[createOrUpdate.method](createOrUpdate.url, f)
        if (res && (res.status === 201 || res.status === 200) && saveback) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
          this.$router.push({
            name: "aboutAct",
            params: {
              act_id: this.$route.params.act_id,
              type: this.$route.params.type
            }
          })
        } else if (res && (res.status === 201 || res.status === 200)) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
        } else {
          this.$message.error(
            `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
          )
        }
      } catch (e) {
        this.$message.error(
          `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
        )
        this.$message.error(e)
        console.error(e)
      }
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
